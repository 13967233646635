<template>
  <div class="layout-wrapper">
    <div id="app-viewport"></div>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import microApps from './micro-app'
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      isLoading: true,
      microApps,
      current: '/mis-tams/',
    }
  },
  watch: {
    isLoading(val) {
      if (val) {
        NProgress.start()
      } else {
        this.$nextTick(() => {
          NProgress.done()
        })
      }
    },
  },
  created() {
    this.bindCurrent()
    NProgress.start()
  },
  mounted() {
    this.listenRouterChange()
  },
  methods: {
    bindCurrent() {
      const path = window.location.pathname
      if (this.microApps.findIndex((item) => item.activeRule === path) >= 0) {
        this.current = path
      }
    },
    listenRouterChange() {
      const _wr = function(type) {
        const orig = history[type]
        return function() {
          const rv = orig.apply(this, arguments)
          const e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent(e)
          return rv
        }
      }
      history.pushState = _wr('pushState')

      window.addEventListener('pushState', this.bindCurrent)
      window.addEventListener('popstate', this.bindCurrent)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('pushState', this.bindCurrent)
        window.removeEventListener('popstate', this.bindCurrent)
      })
    },
  }
}
</script>

<style>
body {
  word-break: break-all;
  font-size: 14px;
  color: #333333;
  background: #f5f6f7;
}
</style>
