import store from './store'

const devPort = (app) => {
  if (app === 'micro-empty') {
    return '9999'
  } else
  if (app === 'micro-tams') {
    return '10000'
  } else if (app === 'micro-lbms') {
    return '10001'
  } else if (app === 'micro-xpedu') {
    return '10002'
  } else if (app === 'micro-operation') {
    return '10003'
  } else if (app === 'micro-protocol') {
    return '10004'
  } else if (app === 'micro-cashier') {
    return '10005'
  } else if (app === 'micro-sxtams') {
    return '10006'
  } else if (app === 'micro-logistics') {
    return '10007'
  } else if (app === 'micro-smart') {
    return '10008'
  } else if (app === 'micro-clue') {
    return '10009'
  }   else {
    return '8081'
  }
}

const baseUrl = (app) => {
  if (process.env.NODE_ENV === 'production') {
    return window.location.origin + '/app'
  } else {
    return 'http://localhost:' + devPort(app) + '/app'
  }
}

const microBuilder = (app) => {
  return {
    name: app,
    entry: baseUrl(app) + '/' + app + '/',
    activeRule: '/' + app,
  }
}

//
const microApps = [
  microBuilder('micro-empty'),
  microBuilder('micro-tams'),
  microBuilder('micro-lbms'),
  microBuilder('micro-xpedu'),
  microBuilder('micro-operation'),
  microBuilder('micro-protocol'),
  microBuilder('micro-cashier'),
  microBuilder('micro-sxtams'),
  microBuilder('micro-logistics'),
  microBuilder('micro-smart'),
  microBuilder('micro-clue'),
]

const apps = microApps.map((item) => {
  return {
    ...item,
    container: '#app-viewport', // 子应用挂载的div
    props: {
      routerBase: item.activeRule, // 下发基础路由
      getGlobalState: store.getGlobalState, // 下发getGlobalState方法
    },
  }
})

export default apps

