import Vue from 'vue'
import App from './App.vue'
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun'
import { sdk as gSDK } from 'common'
import commonStore from './store/index'
import microApps from './micro-app'
import 'nprogress/nprogress.css'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.prototype.gSDK = gSDK
Vue.prototype.eventBus = new Vue()
Vue.config.productionTip = false


Vue.use(ElementUI);
const instance = new Vue({
  commonStore,
  render: h => h(App),
}).$mount('#app')

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (instance && instance.$children) {
    // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
    instance.$children[0].isLoading = loading
  }
}

// 给子应用配置加上loader方法
const apps = microApps.map((item) => {
  return {
    ...item,
    loader,
  }
})

registerMicroApps(apps, {
  beforeLoad: (app) => {
    console.log('before load app.name====>>>>>', app.name)
  },
  beforeMount: [
    (app) => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    },
  ],
  afterMount: [
    (app) => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
    },
  ],
  afterUnmount: [
    (app) => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
    },
  ],
})


setDefaultMountApp('/micro-smart')
start({

  prefetch:false,
  singular:true,
  fetch:window.fetch,
  excludeAssetFilter:(assetUrl)=>{
    const whiteList = []
    const whiteWords = ['tcplayer','pingjs','sdk.51.la','hls.min','ddLogin.js']
    if(whiteList.includes((assetUrl))){
      return true
    }

    return whiteWords.some(w =>{
      return assetUrl.includes(w)
    })
  }
})
