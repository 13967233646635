const getters = {
  userInfo: (state) => state.user.userinfo,
  userId: (state) => state.user.userId,
  token: (state) => state.user.token,
  telephone: (state) => state.user.telephone,
  appId: (state) => state.user.appId,
  userName: (state) => state.user.userName,
  nick: (state) => state.user.nick,
  avatar: (state) => state.user.avatar,
}
export default getters
