import { setStore, getStore } from '../storage'

const user = {
  state: {
    userInfo: getStore({ name: 'userInfo' }) || {},
    userId: getStore({ name: 'userId' }) || '',
    telephone: getStore({ name: 'telephone' }) || '',
    token: getStore({ name: 'token' }) || '',
    appId: getStore({ name: 'appId' }) || '',
    isAdmin: getStore({ name: 'isAdmin' }) || false,
    userName: getStore({ name: 'userName' }) || '',
    nick: getStore({ name: 'nick' }) || '',
    avatar: getStore({ name: 'avatar' }) || '',
  },
  actions: {
    // 登录成功
    LoginSuccess({ commit }, loginResult) {
      commit('SET_USER_ID', loginResult.data.userId)
      commit('SET_TOKEN', loginResult.data.token)
      commit('SET_TELEPHONE', loginResult.data.telephone)
    },
    // 退出登录
    LogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_USER_INFO', {})
        commit('SET_USER_ID', '')
        commit('SET_TOKEN', '')
        commit('SET_TELEPHONE', '')
        commit('SET_USER_NAME', '')
        commit('SET_NICK', '')
        commit('SET_AVATAR', '')
        commit('SET_APP_ID', '')
        resolve()
      })
    },
  },
  mutations: {
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
      setStore({ name: 'userId', content: state.userId })
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      setStore({ name: 'token', content: state.token })
    },
    SET_TELEPHONE: (state, telephone) => {
      state.telephone = telephone
      setStore({ name: 'telephone', content: state.telephone })
    },
    SET_APP_ID: (state, appId) => {
      state.appId = appId
      setStore({ name: 'appId', content: state.appId })
    },
    SET_USER_NAME: (state, userName) => {
      state.userName = userName
      setStore({ name: 'userName', content: state.userName })
    },
    SET_NICK: (state, nick) => {
      state.nick = nick
      setStore({ name: 'nick', content: state.nick })
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
      setStore({ name: 'avatar', content: state.nick })
    },
  },
}
export default user
