import { initGlobalState } from 'qiankun'
import Vue from 'vue'
import commonStore from '@/store/index.js'

// 壳应用的初始state
const initialState = Vue.observable({
  user: {
    userId: commonStore.state.user.userId,
    token: commonStore.state.user.token,
    telephone: commonStore.state.user.telephone,
  },
  currentApp: {
    id: commonStore.state.user.appId,
  },
  event: {
    eventId: '',
    eventData: {},
  },
})

const actions = initGlobalState(initialState)

actions.onGlobalStateChange((newState, oldState) => {
  console.log(
    'master state change',
    JSON.stringify(newState),
    JSON.stringify(oldState)
  )
  commonStore.commit('SET_TELEPHONE', newState.user.telephone || null)
  commonStore.commit('SET_APP_ID', newState.currentApp.id || null)
  let eventId = newState.event.eventId
  // eslint-disable-next-line no-unused-vars
  let eventData = newState.event.eventData
  if (eventId === 'logout') {
    commonStore.dispatch('LogOut').then(() => {
      actions.setGlobalState({
        event: {},
      })
    })
  }
  for (const key in newState) {
    initialState[key] = newState[key]
  }
})

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  return key ? initialState[key] : initialState
}

export default actions
